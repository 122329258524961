import { graphql } from 'gatsby';
import React from 'react';
import {
  translationsFactory,
  TranslationsContext,
  Translation,
} from 'settings/i18n';
import EducationNavigation from 'components/EducationNavigation';
import Footer from 'components/Footer';
import SEO, { SEOMeta } from 'components/SEO/SEO';
import EducationQuiz from 'components/EducationQuiz/EducationQuiz';
import ClientOnly from 'components/ClientOnly/ClientOnly';

import { QuizMachineContext } from 'components/EducationQuiz/EducationQuizMachine';

const EducationCryptocurrencyQuiz = ({
  data,
  pageContext: { url },
}: {
  data: {
    translations: Translation;
    education: Translation;
    homepage: Translation;
    common: Translation;
    metadata: SEOMeta;
  };
  pageContext: {
    url: string;
  };
}) => {
  const t = translationsFactory({
    wpPage: {
      id: 'translations',
      translation: {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        ...data?.translations?.quiz,
        ...data?.education?.translation,
        ...data?.common?.translation,
        ...data?.homepage?.translation,
      },
    },
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const { quiz } = data.translations;

  const questions: QuizMachineContext['questions'] = [
    {
      id: 'qb1',
      question: 'firstQuestion',
      answers: [
        'firstQuestionFirstAnswer',
        'firstQuestionSecondAnswer',
        'firstQuestionThirdAnswer',
      ],
      correctAnswerIndex: Number(quiz.firstQuestionCorrectAnswerNumber) - 1,
    },
    {
      id: 'qb2',
      question: 'secondQuestion',
      answers: [
        'secondQuestionFirstAnswer',
        'secondQuestionSecondAnswer',
        'secondQuestionThirdAnswer',
      ],
      correctAnswerIndex: Number(quiz.secondQuestionCorrectAnswerNumber) - 1,
    },
    {
      id: 'qb3',
      question: 'thirdQuestion',
      answers: [
        'thirdQuestionFirstAnswer',
        'thirdQuestionSecondAnswer',
        'thirdQuestionThirdAnswer',
      ],
      correctAnswerIndex: Number(quiz.thirdQuestionCorrectAnswerNumber) - 1,
    },
    {
      id: 'qb4',
      question: 'fourthQuestion',
      answers: [
        'fourthQuestionFirstAnswer',
        'fourthQuestionSecondAnswer',
        'fourthQuestionThirdAnswer',
      ],
      correctAnswerIndex: Number(quiz.fourthQuestionCorrectAnswerNumber) - 1,
    },
    {
      id: 'qbt',
      question: 'fifthQuestion',
      answers: [
        'fifthQuestionFirstAnswer',
        'fifthQuestionSecondAnswer',
        'fifthQuestionThirdAnswer',
      ],
      correctAnswerIndex: Number(quiz.fifthQuestionCorrectAnswerNumber) - 1,
    },
    {
      id: 'qb5',
      question: 'sixthQuestion',
      answers: [
        'sixthQuestionFirstAnswer',
        'sixthQuestionSecondAnswer',
        'sixthQuestionThirdAnswer',
      ],
      correctAnswerIndex: Number(quiz.sixthQuestionCorrectAnswerNumber) - 1,
    },
    {
      id: 'qb6',
      question: 'seventhQuestion',
      answers: [
        'seventhQuestionFirstAnswer',
        'seventhQuestionSecondAnswer',
        'seventhQuestionThirdAnswer',
      ],
      correctAnswerIndex: Number(quiz.seventhQuestionCorrectAnswerNumber) - 1,
    },
    {
      id: 'qb7',
      question: 'eighthQuestion',
      answers: [
        'eighthQuestionFirstAnswer',
        'eighthQuestionSecondAnswer',
        'eighthQuestionThirdAnswer',
      ],
      correctAnswerIndex: Number(quiz.eighthQuestionCorrectAnswerNumber) - 1,
    },
    {
      id: 'qb8',
      question: 'ninethQuestion',
      answers: [
        'ninethQuestionFirstAnswer',
        'ninethQuestionSecondAnswer',
        'ninethQuestionThirdAnswer',
      ],
      correctAnswerIndex: Number(quiz.ninethQuestionCorrectAnswerNumber) - 1,
    },
    {
      id: 'qb10',
      question: 'tenthQuestion',
      answers: [
        'tenthQuestionFirstAnswer',
        'tenthQuestionSecondAnswer',
        'tenthQuestionThirdAnswer',
      ],
      correctAnswerIndex: Number(quiz.tenthQuestionCorrectAnswerNumber) - 1,
    },
  ];

  return (
    <TranslationsContext.Provider value={t}>
      <SEO metadata={data.metadata} url={url} />
      <ClientOnly>
        <EducationNavigation />
        <EducationQuiz questions={questions} />
        <Footer />
      </ClientOnly>
    </TranslationsContext.Provider>
  );
};

export default EducationCryptocurrencyQuiz;

export const query = graphql`
  query($locale: String!, $slug: String!) {
    translations: wpPost(
      slug: { eq: $slug}
      language: { locale: { eq: $locale } }
    ) {
      quiz {
        quizStartText

        firstQuestion
        firstQuestionCorrectAnswerNumber
        firstQuestionFirstAnswer
        firstQuestionSecondAnswer
        firstQuestionThirdAnswer

        secondQuestion
        secondQuestionCorrectAnswerNumber
        secondQuestionFirstAnswer
        secondQuestionSecondAnswer
        secondQuestionThirdAnswer

        thirdQuestion
        thirdQuestionCorrectAnswerNumber
        thirdQuestionFirstAnswer
        thirdQuestionSecondAnswer
        thirdQuestionThirdAnswer

        fourthQuestion
        fourthQuestionCorrectAnswerNumber
        fourthQuestionFirstAnswer
        fourthQuestionSecondAnswer
        fourthQuestionThirdAnswer

        fifthQuestion
        fifthQuestionCorrectAnswerNumber
        fifthQuestionFirstAnswer
        fifthQuestionSecondAnswer
        fifthQuestionThirdAnswer

        sixthQuestion
        sixthQuestionCorrectAnswerNumber
        sixthQuestionFirstAnswer
        sixthQuestionSecondAnswer
        sixthQuestionThirdAnswer

        seventhQuestion
        seventhQuestionCorrectAnswerNumber
        seventhQuestionFirstAnswer
        seventhQuestionSecondAnswer
        seventhQuestionThirdAnswer

        eighthQuestion
        eighthQuestionCorrectAnswerNumber
        eighthQuestionFirstAnswer
        eighthQuestionSecondAnswer
        eighthQuestionThirdAnswer

        ninethQuestion
        ninethQuestionCorrectAnswerNumber
        ninethQuestionFirstAnswer
        ninethQuestionSecondAnswer
        ninethQuestionThirdAnswer

        tenthQuestion
        tenthQuestionCorrectAnswerNumber
        tenthQuestionFirstAnswer
        tenthQuestionSecondAnswer
        tenthQuestionThirdAnswer
      }
    }

    common: wpPage(
      slug: { regex: "/\\b(\\w*quiz-blockchain\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      ...EducationQuizTranslation
    }

    metadata: wpPost(
      slug: { eq: $slug }
      language: { locale: { eq: $locale } }
    ) {
      seo {
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        opengraphImage {
          localFile {
            name
            extension
          }
          mimeType
          mediaDetails {
            height
            width
          }
        }
        title
        twitterDescription
        twitterTitle
    }
    }

    homepage: wpPage(
      slug: { regex: "/\\b(\\w*home-page\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      ...HeaderTranslation
      ...FooterTranslation
      ...AboutTranslation
    }
  }
`;
